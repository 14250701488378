<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Mutasi Masuk : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- status mutasi masuk -->
          <b-form-group label="Status Mutasi" label-for="status_mut_masuk">
            <validation-provider #default="{ errors }" name="status_mut_masuk">
              <v-select
                id="status_mut_masuk"
                v-model="filter.mutmasuk_status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusMutMasuk"
                placeholder="pilih status mutasi..."
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- asal cabang -->
          <b-form-group label="Asal Cabang" label-for="asal_cabang">
            <validation-provider #default="{ errors }" name="asal_cabang">
              <b-form-input
                id="asal_cabang"
                placeholder="Search asal cabang"
                v-model="filter.mutmasuk_asal_cabang"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama -->
          <b-form-group label="Nama Anggota" label-for="nama">
            <validation-provider #default="{ errors }" name="nama">
              <b-form-input
                id="nama"
                placeholder="Search nama anggota"
                v-model="filter.orang_nama_lengkap"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- asal wilayah -->
          <b-form-group label="Asal Wilayah" label-for="asal_wilayah">
            <validation-provider #default="{ errors }" name="asal_wilayah">
              <b-form-input
                id="asal_wilayah"
                placeholder="Search asal wilayah"
                v-model="filter.mutmasuk_asal_wilayah"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- tujuan masuk -->
          <b-form-group label="Tujuan Masuk" label-for="tujuan_masuk">
            <validation-provider #default="{ errors }" name="tujuan_masuk">
              <b-form-input
                id="tujuan_masuk"
                placeholder="Search tujuan masuk"
                v-model="filter.mutmasuk_tujuan"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- tujuan masuk -->
          <b-form-group label="Tanggal" label-for="tanggal">
            <validation-provider #default="{ errors }" name="tanggal">
              <b-form-input
                id="tanggal"
                placeholder="Search tujuan masuk"
                v-model="filter.created_at"
                type="date"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="2" class="mb-50">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalApproveData(data.item)">
            <feather-icon icon="CheckCircleIcon" size="16" />
            <span class="align-middle ml-50">Setujui</span>
          </b-dropdown-item>
          <!-- hide sementara next akan di improve-->
          <!-- <b-dropdown-item @click="donwloadDocument(data.item)">
            <feather-icon icon="DownloadIcon" size="16" />
            <span class="align-middle ml-50">Download Dokumen</span>
          </b-dropdown-item> -->
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- modal approve-->
    <b-modal
      id="modal-approve"
      title="Approve Mutasi Masuk"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeApproveMutmasuk">
        <b-form @submit.prevent>
          <b-form-group>
            <table style="width: 100%">
              <tr>
                <td>File</td>
                <td>:</td>
                <td v-if="approveData.mutmasuk_file_ketpindah != null">
                  <a
                    v-if="
                      !approveData.mutmasuk_file_ketpindah.includes('https') &&
                        !approveData.mutmasuk_file_ketpindah.includes('base64')
                    "
                    :href="
                      'http://www.staging.idijakpus.or.id/uploads/mutmasuk/mutmasuk_file_ketpindah/' +
                        approveData.id +
                        '/' +
                        approveData.mutmasuk_file_ketpindah
                    "
                    alt="pdf"
                    target="_blank"
                    >Tampilkan File</a
                  >
                  <a
                    v-else
                    :href="approveData.mutmasuk_file_ketpindah"
                    target="_blank"
                    >Tampilkan File</a
                  >
                </td>
                <td v-else>{{ "tidak ada file" }}</td>
              </tr>
            </table>
          </b-form-group>
          <br />
          <b-form-group>
            <label for="id">Id:</label>
            <b-form-input
              id="id"
              type="text"
              placeholder="id"
              v-model="approveData.id"
              readonly
            />
          </b-form-group>
          <b-form-group label="Status" label-for="status_mutasi">
            <validation-provider
              #default="{ errors }"
              name="Status Mutasi Masuk"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <v-select
                  id="status_mutasi"
                  v-model="mutmasuk"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="errors.length > 0 ? false : null"
                  :options="option"
                  placeholder="Status Mutasi.."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="File Mutasi Masuk" label-for="file_kta">
            <validation-provider
              #default="{ errors }"
              name="File Mutasi Masuk"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-file
                  multiple
                  v-model="mutmasuk_file_ketpindah"
                  :file-name-formatter="formatNames"
                  :state="errors.length > 0 ? false : null"
                  @change="handlerFileMutasiMasuk($event)"
                  accept="image/*"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeApproveMutmasuk"
          >
            Approve
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "orang_id.orang_nama_lengkap", label: "Nama Anggota" }],
        [{ key: "mutmasuk_asal_cabang", label: "asal cabang" }],
        [{ key: "mutmasuk_asal_wilayah", label: "asal wilayah" }],
        [{ key: "mutmasuk_tujuan", label: "tujuan" }],
        [{ key: "mutmasuk_status", label: "status" }],
        [{ key: "created_at", label: "tanggal" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      search: null,
      base64Data: "",
      option: ["selesai-tolak", "selesai-sukses"],
      approveData: {},
      statusMutMasuk: [
        "selesai-sukses",
        "selesai-tolak",
        "diajukan",
        "sedang-dibuat",
      ],
      filter: {
        mutmasuk_status: null,
        mutmasuk_asal_cabang: null,
        mutmasuk_asal_wilayah: null,
        mutmasuk_tujuan: null,
        created_at: null,
        orang_nama_lengkap: null,
      },

      mutmasuk_file_ketpindah: "",
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getMutasiMasuk();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalApproveData(item) {
      this.approveData = item;
      this.$bvModal.show("modal-approve");
    },

    validateBeforeApproveMutmasuk() {
      this.$refs.validateBeforeApproveMutmasuk.validate().then((success) => {
        if (success) {
          this.approveMutasiMasuk();
        }
      });
    },

    reloadPage() {
      location.reload();
    },

    handlerFileMutasiMasuk(e) {
      const { files } = e.target;
      if (files.length) {
        this.createhandlerFileMutasiMasuk(files[0], (result) => {
          this.mutmasuk_file_ketpindah = result;
        });
      }
    },
    createhandlerFileMutasiMasuk(file, cb) {
      const reader = new FileReader();
      reader.onload = (e) => {
        cb(e.target.result);
      };
      reader.readAsDataURL(file);
    },

    async getMutasiMasuk() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.mutasi_masuk.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data mutasi masuk");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async approveMutasiMasuk() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        var id = this.approveData.id;
        var data = {
          mutmasuk_status: this.mutmasuk,
          mutmasuk_file_ketpindah: this.mutmasuk_file_ketpindah,
        };
        console.log(data);
        await API.mutasi_masuk.setuju(id, data);
        this.handleMsgSuccess("Berhasil approve mutasi masuk");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "form yang anda isi salah");
      } finally {
        this.$bvModal.hide("modal-approve");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getMutasiMasuk();
  },
  watch: {
    currentPage() {
      this.getMutasiMasuk();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
